import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'

import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function CoachingPage({ data: { strapi: { coaching } }, location }) {

	return (
		<>
			<SEO title="Coaching" description={ coaching.seoDescription } pathname={ location.pathname } />

			<div className="content-page">

				{ coaching && coaching.content &&
					<DynamicZone components={ coaching.content } />
				}
			</div>
		</>
	)
}

export const query = graphql`
	query coachingPageQuery {
		strapi { 
			coaching {
				seoDescription
				content {
					... on StrapiQuery_ComponentCoachingTeamMembersList {
						id
						heading
						members {
							description
							id
							name
							role
							image {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 400) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCoachingPriceGuide {
						id
						heading
						summary
						sessions {
							description
							id
							name
							price
						}
					}
					... on StrapiQuery_ComponentCommonCarousel {
						id
						carouselTitle
						carouselSlides {
							id
							testimonial {
								company
								id
								name
								slug
								summary
								thumbnail {
									url
									imageFile {
										childImageSharp {
											fluid(maxWidth: 400) {
												...GatsbyImageSharpFluid_noBase64
											}
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonStatement {
						id
						statement
						url
						urlText
					}
					... on StrapiQuery_ComponentCommonVideo {
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentCommonImage {
						id
						displayBorder
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonTestimonial {
						id
						introduction
						testimonial {
							slug
							thumbnail {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 400) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonContactForm {
						id
						message
					}
					... on StrapiQuery_ComponentCommonDivider {
						id
						display
					}
					... on StrapiQuery_ComponentCommonQuote {
						id
						author
						quote
					}
				}
			}
		}
	}
`